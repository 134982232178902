// Первоначальный вариант, который получал ответ с сервера:

document.querySelector('.form__btn-submit').addEventListener('click', function(event) {
    event.preventDefault(); // Предотвращаем перезагрузку страницы при отправке формы


    const xhr = new XMLHttpRequest();
    const url = 'https://api.im-pulse.ai/public/feedback';

    const name = document.getElementById('applicant_name').value.trim();
    const phone = document.getElementById('applicant_phone').value.trim();
    const theme = document.getElementById('applicant_subject').value.trim();
    const message = document.getElementById('applicant_text').value.trim();
    const sender_email = document.getElementById('applicant_email').value.trim();
    const checkbox = document.getElementById('applicant_agree');

    const name_label = document.querySelector(`label[for="applicant_name"]`);

    if (name === '') {
        document.getElementById('applicant_name').style.borderColor = 'red';
        document.querySelector(`label[for="applicant_name"]`).innerText = 'Пожалуйста, введите ваше имя';
        return;
    } else {
        document.getElementById('applicant_name').style.borderColor = '#38ACF3';
        document.querySelector(`label[for="applicant_name"]`).innerText = 'Ваше имя';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(sender_email)) {
        document.getElementById('applicant_email').style.borderColor = 'red';
        document.querySelector(`label[for="applicant_email"]`).innerText = 'Пожалуйста, введите корректный email';
        return;
    } else {
        document.getElementById('applicant_email').style.borderColor = '#38ACF3';
        document.querySelector(`label[for="applicant_email"]`).innerText = 'Email';
    }

    const phoneRegex = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
    if (!phoneRegex.test(phone)) {
        document.getElementById('applicant_phone').style.borderColor = 'red';
        document.querySelector(`label[for="applicant_phone"]`).innerText = 'Пожалуйста, введите корректный номер телефона';
        return;
    } else {
        document.getElementById('applicant_phone').style.borderColor = '#38ACF3';
        document.querySelector(`label[for="applicant_phone"]`).innerText = 'Телефон';
    }

    if (theme === '') {
        document.getElementById('applicant_subject').style.borderColor = 'red';
        document.querySelector(`label[for="applicant_subject"]`).innerText = 'Пожалуйста, введите тему сообщения';
        return;
    } else {
        document.getElementById('applicant_subject').style.borderColor = '#38ACF3';
        document.querySelector(`label[for="applicant_subject"]`).innerText = 'Тема обращения';
    }

    if (message === '') {
        document.getElementById('applicant_text').style.borderColor = 'red';
        document.querySelector(`label[for="applicant_text"]`).innerText = 'Пожалуйста, введите текст сообщения';
        return;
    } else {
        document.getElementById('applicant_text').style.borderColor = '#38ACF3';
        document.querySelector(`label[for="applicant_text"]`).innerText = 'Текст обращения';
    }

    if (!checkbox.checked) {
        document.querySelector('.form__checkbox-hidden').style.visibility = 'visible';
        document.querySelector('.form__checkbox-hidden').style.height = '100%';
        return;
    } else {
        document.querySelector('.form__checkbox-hidden').style.visibility = 'hidden';
        document.querySelector('.form__checkbox-hidden').style.height = '0';
    }




    let formData = {
        name: name,
        theme: theme,
        message: message,
        sender_email: sender_email,
    };



xhr.open('POST', url, true);
xhr.setRequestHeader('Content-Type', 'application/json');
// xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
// xhr.setRequestHeader('Access-Control-Allow-Credentials', 'true');
// xhr.setRequestHeader('Access-Control-Allow-Methods', 'OPTIONS, GET, HEAD, POST, PUT, DELETE, CONNECT, TRACE, PATCH');
// xhr.setRequestHeader('Access-Control-Allow-Headers', 'Origin, Authorization, Content-Type, Removing-User, Access, Accept, Email');

    xhr.setRequestHeader('x-yc-apigateway-integration', JSON.stringify({
        type: 'cloud_functions',
        function_id: 'd4e6ost6lhpfig9hemnm',
        service_account_id: 'ajens7akjmoj0hso818d'
    }));

xhr.onreadystatechange = function() {
    if (xhr.readyState === 4 && xhr.status === 200) {
        let response = JSON.parse(xhr.responseText);
        console.log('success');
        console.log(response);
    }
};

let requestBody = JSON.stringify(formData);
    console.log(requestBody);
xhr.send(requestBody);
});


// Новый вариант:
// const feedbackForm = document.getElementById('feedbackForm');
//
// feedbackForm.onsubmit = async (e) => {
//     e.preventDefault();

// OPTIONS ЗАПРОС:

//     let response1 = await fetch('https://api.im-pulse.ai/public/feedback', {
//         method: 'OPTIONS',
//         headers: {
//             "Access-Control-Allow-Origin": "*",
//             "Access-Control-Allow-Methods": "OPTIONS, GET, HEAD, POST, PUT, DELETE, CONNECT, TRACE, PATCH",
//             "Access-Control-Allow-Headers": "Authorization, Content-Type, Removing-User, Access, Email",
//             "Access-Control-Allow-Credentials": true,
//             "x-yc-apigateway-integration": JSON.stringify({
//                 type: 'dummy',
//                 http_code: 200,
//                 content: 'application/json'
//             }),
//             "operationId": "CORS for feedback"
//         }
//         });
//
//     let result = await response1.json();
//     console.log(response1.message);
//
// }

// POST ЗАПРОС:

//     let response = await fetch('https://api.im-pulse.ai/public/feedback', {
//         method: 'POST',
//         body: new FormData(feedbackForm),
//         headers: {
//             "Content-Type": "application/json",
//             "x-yc-apigateway-integration": JSON.stringify({
//                 type: 'cloud_functions',
//                 function_id: 'd4e6ost6lhpfig9hemnm',
//                 service_account_id: 'ajens7akjmoj0hso818d'
//             })
//         },
//     });
//
// console.log(response.body);
//     let result = await response.json();
//
// }